@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  *:not(svg path, svg rect, svg circle, svg polygon) {
    @apply font-sans;
  }
}

/* lexend-regular - latin */
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/lexend-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/lexend-v5-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/lexend-v5-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/lexend-v5-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('./fonts/lexend-v5-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/lexend-v5-latin-regular.svg#Lexend')
      format('svg'); /* Legacy iOS */
}

input:checked + svg {
  display: block;
}

.vis-tooltip {
  @apply absolute bg-white text-gray-400 p-2 text-xs tracking-wider;

  max-width: 200px;
}

.external-link:after {
  content: ' ↗';
}

/* TODO: remove this class after upgrade to tailwind v3.x */
.align-super {
  vertical-align: super;
}
